import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { dateToDatabaseString, MY_DOMAIN, useFormState } from "../../utils/utils";

import Utils from "../Utils";

import style from "./FormFields.module.css";
import { UserContextType, useUser } from "../../utils/context/User";
import { useHistory, useLocation } from "react-router-dom";
import { Category, Field, PostCategory, SmallCompany } from "../../utils/api/_type";
import { checkStatusQuiet, host_core } from "../../utils/api";
import { InputGroup } from "react-bootstrap";

interface Props {
  isLoading?: boolean;
  category: Category | PostCategory;
  onSubmit?: (body: { answerItems: { field: number; value: string | boolean | File }[]; freeField?: string }) => void;
}

export type FormInputObject = { [key: string]: string | boolean | File[] };

export default function FormFields({ category, onSubmit, isLoading = false }: Props) {
  const [user] = useUser() as UserContextType;
  const history = useHistory();
  const location = useLocation();

  const [companyLogo, setCompanyLogo] = useState<string | undefined>(undefined);
  const [isModalMediaOpen, setIsModalMediaOpen] = useState<boolean>(false);

  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [formState, handleInput, resetForm] = useFormState<FormInputObject>({});

  function handleSelectInput(id: number, val: string) {
    resetForm(((b: any) => ({ ...b, [id + ""]: val })) as any);
  }

  useEffect(() => {
    fetch(host_core + "/api/companies/brand-image/" + category.company?.id)
      .then(checkStatusQuiet)
      .then((res) =>
        res
          .blob()
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => setCompanyLogo(url))
      );

    let o: { [key: string]: Yup.BaseSchema } = {};
    let form: FormInputObject = {};
    category.fields
      .filter((f) => f.required !== "pilote")
      .forEach((f) => {
        o[f.id + ""] = generateSchema(f);
        form[f.id + ""] = generateObject(f);
      });

    setValidationSchema(Yup.object().shape(o));
    resetForm(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  function generateSchema(f: Field): Yup.AnySchema {
    switch (f.type) {
      case "bool":
        return r(Yup.bool(), f);
      case "date":
        return r(Yup.string(), f);
      case "int":
        return r(Yup.string(), f);
      case "string":
        return r(Yup.string(), f);
      case "select":
        return r(Yup.mixed(), f);
      case "likert":
        return r(Yup.mixed(), f);
      case "file":
        return r(Yup.mixed(), f);
      default:
        return r(Yup.mixed(), f);
    }

    function r(s: Yup.BaseSchema, f: Field) {
      if (f.required === "required") {
        if (f.type === "bool") {
          return (s as Yup.BooleanSchema).required().oneOf([true]);
        } else if (f.type === "select") {
          return s.test("checkNotEmpty", "Le choix est vide", (value: string) => {
            return !(value === "");
          });
        } else {
          return s.required();
        }
      } else {
        return s;
      }
    }
  }

  function generateObject(f: Field): string | boolean {
    switch (f.type) {
      case "bool":
        return false;
      case "date":
        return "";
      case "int":
        return "";
      case "string":
        return "";
      case "select":
        return "";
      case "file":
        return "";
      default:
        return "";
    }
  }

  function handleSubmit() {
    if (validationSchema.isValidSync(formState)) {
      if (onSubmit !== undefined) {
        onSubmit({
          answerItems: Object.keys(formState).map((k) => ({
            field: parseInt(k),
            value: formatValue(formState[k], k),
          })),
          freeField: freeField !== "" ? freeField : undefined,
        });
      }
    }
  }

  function formatValue(value: string | boolean | File[], k: string): string | boolean | File {
    if (typeof value === "boolean") return value;

    let field = category.fields.filter((f) => f.id === parseInt(k))[0];

    if (typeof value === "object") return value[0];

    if (field === undefined) return value;
    if (value === "") return "";

    if (field.type === "date") return dateToDatabaseString(new Date(value));
    else return value;
  }

  // Mode de connexion
  const [freeField, setFreeField] = useState("");

  function handleConnexion() {
    history.push("/login#redirect=" + location.pathname);
  }

  // Verification de si on peut valider le formulaire
  function isInvalidForm() {
    let isInvalid = false;
    if (!validationSchema.isValidSync(formState) || onSubmit === undefined) {
      isInvalid = true;
    }

    if (category.authMethod === "connection" && user === undefined) {
      isInvalid = true;
    }

    if (category.authMethod === "text" && freeField === "") {
      isInvalid = true;
    }

    return isInvalid;
  }

  function getLabel(field: Field) {
    return (
      <>
        {field.name}{" "}
        {field.required === "required" ? (
          <span style={{ color: category.color ? `#${category.color}` : "red", fontWeight: "bold" }}>*</span>
        ) : field.required === "pilote" ? (
          <span style={{ color: category.color ? `#${category.color}` : "grey", fontWeight: "lighter" }}>(dédié aux administrateurs)</span>
        ) : null}{" "}
      </>
    );
  }

  function getDescription(field: Field) {
    return <>{field.properties.description ? field.properties.description : <></>}</>;
  }

  return (
    <>
      {category.showLogo && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "10rem",
              height: "10rem",
              marginTop: "2rem",
              marginBottom: "2rem",
              borderRadius: "16px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundImage: `url(${companyLogo})`,
              backgroundColor: "transparent",
            }}
          />
        </div>
      )}
      {category.authMethod === "anything" || category.authMethod === "text" ? (
        <Utils.Input
          label="Votre nom"
          value={freeField}
          onChange={(e) => setFreeField(e.target.value)}
          isInvalid={category.authMethod === "text" && freeField === ""}
          invalidColor={category.color}
        />
      ) : null}

      {category.authMethod === "anything" || category.authMethod === "connection" ? (
        // Utilisateur est connecté
        user !== undefined ? (
          <p className={style.connectionRow}>
            Connecté en tant que{" "}
            <span style={{ fontWeight: "600" }}>
              {user.firstname} {user.lastname}
            </span>
          </p>
        ) : (
          // Non connecté
          <Utils.Button variant="secondary" disabled={onSubmit === undefined} onClick={handleConnexion}>
            Se connecter
          </Utils.Button>
        )
      ) : null}

     {isInvalidForm() && <p className={style.title} style={{ color: category.color ? `#${category.color}` : "var(--primary-color)" }}>
        Veuillez remplir le formulaire s'il vous plait.
      </p>}
      
      {category.fields
        // .sort((f) => (f.required === "pilote" ? 1 : -1))  ça fait de la merde
        .map((field, index) => (
          <>
            {index !== 0 && <div style={{ width: "100%", height: "1px", margin: "10px 0 10px 0", backgroundColor: "var(--background)" }} />}
            {field.type === "title" ? (
              <div key={field.id}>
                <span className={style.label} color={category.color || undefined}>
                  {getLabel(field)}
                </span>
                <p>
                  <span style={{whiteSpace:"break-spaces"}}>{getDescription(field)}</span>
                </p>
              </div>
            ) : field.type === "bool" ? (
              <Utils.Checkbox
                key={field.id}
                checked={formState[field.id + ""] as boolean}
                onChange={handleInput}
                label={getLabel(field)}
                isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
                invalidColor={category.color}
                name={field.id + ""}
              />
            ) : field.type === "date" ? (
              <Utils.Input
                key={field.id}
                label={getLabel(field)}
                type="date"
                name={field.id + ""}
                value={formState[field.id + ""] as string}
                isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
                onChange={handleInput}
                invalidColor={category.color}
              />
            ) : field.type === "int" ? (
              <Utils.Input
                key={field.id}
                label={getLabel(field)}
                type="number"
                name={field.id + ""}
                value={formState[field.id + ""] as string}
                onChange={handleInput}
                isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
                invalidColor={category.color}
              />
            ) : field.type === "string" ? (
              <Utils.Textarea
                key={field.id}
                label={getLabel(field)}
                name={field.id + ""}
                value={formState[field.id + ""] as string}
                onChange={handleInput}
                isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
                invalidColor={category.color}
              />
            ) : field.type === "select" ? (
              <Utils.Select
                key={field.id}
                label={getLabel(field)}
                value={formState[field.id + ""] as string}
                onChange={(val) => handleSelectInput(field.id, val)}
                isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
                invalidColor={category.color}
              >
                <option value="">Pas de sélection...</option>
                {field.properties.choices!.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </Utils.Select>
            ) : field.type === "likert" ? (
              <>
                <label style={{ fontWeight: 600, fontSize: "1rem", marginBottom: 0 }}>{getLabel(field)}</label>

                <div
                  style={{
                    display: "flex",
                    flexDirection: field.properties.choices?.some((f) => f.length > 8) ? "column" : "row",
                    justifyContent: "space-between",
                  }}
                >
                  {field.properties.choices!.map((c, index) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        key={index}
                        // name={"rb_" + field.id}
                        name={field.id + ""}
                        type="radio"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleInput(e, c);
                          }
                        }}
                      />
                      <span style={{ marginLeft: "0.5rem" }}>{c}</span>
                    </div>
                  ))}
                </div>
              </>
            ) : // <Utils.Select
            //   key={field.id}
            //   label={getLabel(field)}
            //   value={formState[field.id + ""] as string}
            //   onChange={(val) => handleSelectInput(field.id, val)}
            //   isInvalid={!(validationSchema.fields[field.id + ""] as Yup.AnySchema)?.isValidSync(formState[field.id + ""])}
            //   invalidColor={category.color}
            // >
            //   <option value="">Pas de sélection...</option>
            //   {field.properties.choices!.map((c) => (
            //     <option key={c} value={c}>
            //       {c}
            //     </option>
            //   ))}
            // </Utils.Select>
            field.type === "file" ? (
              <>
                <span className={style.label}>{field.name}</span> {getLabel(field)}
                <Utils.Input
                  onClick={(e) => {
                    if (category.company?.properties?.access?.capture !== 1) {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsModalMediaOpen(true);
                    }
                  }}
                  key={field.id}
                  type="file"
                  accept="image/png, image/jpeg, "
                  onChange={handleInput}
                  name={field.id + ""}
                  invalidColor={category.color}
                />
                <Utils.Modal isOpen={isModalMediaOpen} onClose={() => setIsModalMediaOpen(false)} closable title="Utilisation de médias">
                  Pour utiliser des médias dans vos rubriques vous devez être abonné à{" "}
                  <span className={style.link} onClick={() => window.open("https://inari.c-koya.tech/apps")}>
                    Photos & Vidéos
                    <img src={`${MY_DOMAIN}/img/media-icon.png`} style={{ height: "1.5rem", width: "1.5rem", marginLeft: "0.5rem" }} />
                  </span>
                </Utils.Modal>
              </>
            ) : null}
          </>
        ))}

      <Utils.Button
        style={{ backgroundColor: category.color ? `#${category.color}` : "" }}
        variant="primary"
        onClick={handleSubmit}
        disabled={isInvalidForm()}
        isLoading={isLoading}
        fullWidth={false}
        format="square"
      >
        Envoyer
      </Utils.Button>
    </>
  );
}
